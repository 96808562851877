// export const baseUrl = "http://localhost:3070";         // Local
//export const baseUrl = "http://3.7.83.168:3070";        // Dev
//export const baseUrl = "http://3.7.83.168:3075";        // QA
export const baseUrl = "https://test.laundryhub.me";      // Test
//export const baseUrl = "https://laundryhub.me";         // Live

export const apiAccessKey = "945]Y3x[aRJS}DxE";

//export const facebook_app_id = "792527121579809";
export const facebook_app_id = "715516459089348";
export const google_client_id = "259825229465-4b7q54bjj0uh8v0u758o8co67dsmth8k.apps.googleusercontent.com";
export const google_map = "AIzaSyB5EY6eaMSuDV_wREo6bUWKVrczpOmQPw4";

export const firebaseConfig = {
  apiKey: "AIzaSyBfSSe-Xhq-pOgio0KZF8KChYYDv0szmJw",
  authDomain: "laundryhub-297605.firebaseapp.com",
  projectId: "laundryhub-297605",
  storageBucket: "laundryhub-297605.appspot.com",
  messagingSenderId: "967512242871",
  appId: "1:967512242871:web:113ed9454305c0e8bdeb32",
};
