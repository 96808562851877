import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { Nav, NavItem, Collapse, NavbarToggler } from "reactstrap";
import { NotificationManager } from "react-notifications";
import AuthService from "../../services/auth.service";
import Http from "../../helpers/Http";
import ApiRoutes from "../../helpers/ApiRoutes";
import { Translation } from "react-i18next";
import { ThemeContext } from "styled-components";

function SidebarAddClass() {
  document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
}

function SidebarRemoveClass() {
  document.getElementById("OnscrollBody").classList.remove("show_sidebar");
}

const menuItems = [
  {
    to: "/",
    label: "menu.home",
    icon: "fas fa-home",
  },
  {
    to: "/manage-orders",
    label: "menu.myOrders",
    icon: "fas fa-clipboard-list",
  },
  {
    to: "/manage-profile",
    label: "menu.Account",
    icon: "fas fa-user-circle",
  },
  {
    to: "/favourites",
    label: "menu.favourite",
    icon: "fas fa-heart",
  },
  {
    to: "/promocodes",
    label: "menu.promocodes",
    icon: "fas fa-gift",
  },
  {
    to: "/notifications",
    label: "menu.notifications",
    icon: "fas fa-bell",
  },
  {
    to: "/promotions",
    label: "menu.promotions",
    icon: "fas fa-gifts",
  },
  {
    to: "/wallet-history",
    label: "menu.wallet",
    icon: "fas fa-shopping-bag",
  },
  {
    to: "/settings",
    label: "menu.settings",
    icon: "fas fa-cog",
  },

];
const menuItemsCorporate = [
  {
    to: "/",
    label: "menu.home",
    icon: "fas fa-home",
  },
  {
    to: "/manage-orders",
    label: "menu.myOrders",
    icon: "fas fa-clipboard-list",
  },
  {
    to: "/manage-profile",
    label: "menu.Account",
    icon: "fas fa-user-circle",
  },
  {
    to: "/favourites",
    label: "menu.favourite",
    icon: "fas fa-heart",
  },
  {
    to: "/promocodes",
    label: "menu.promocodes",
    icon: "fas fa-gift",
  },
  {
    to: "/notifications",
    label: "menu.notifications",
    icon: "fas fa-bell",
  },
  {
    to: "/promotions",
    label: "menu.promotions",
    icon: "fas fa-gifts",
  },
  {
    to: "/wallet-history",
    label: "menu.wallet",
    icon: "fas fa-shopping-bag",
  },
  {
    to: "/settings",
    label: "menu.settings",
    icon: "fas fa-cog",
  },
  {
    to: "/corporate-package",
    label: "menu.corporate-package",
    icon: "fas fa-briefcase",
  },
];

export default class Sidebar extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.state = {
      activeItem: "",
      is_corporate_customer: 0
    };
  }

  componentDidMount() {
    let getCustomerInfo = localStorage.getItem('customerData');

    let parsedgetCustomerInfo = JSON.parse(getCustomerInfo);
    if (parsedgetCustomerInfo && parsedgetCustomerInfo.is_corporate_customer && parsedgetCustomerInfo.is_corporate_customer != undefined) {
      this.setState({
        is_corporate_customer: parsedgetCustomerInfo.is_corporate_customer
      })
    }
  }

  toggle = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  onLogout = async () => {
    let formData = new FormData();
    formData.append("device_udid", "");

    let path = ApiRoutes.LOGOUT;
    const res = await Http("PUT", path, formData);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        AuthService.clearAuthData();

        window.location.reload();
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error("Server Error", "Error!", 3000);
    }
  };

  render() {
    var pathname = this.props.location.pathname;
    const theme = this.context;
    return (
      <Translation>
        {(t) => (
          <>
            <nav className="sidebar">
              {/* <Link to="/" className="logo">
                <img src="./assets/img/logo.png" alt="LaundryHUB" />
              </Link> */}

              <ul className="sidebar_nav d-block w-100">
                {
                  this.state.is_corporate_customer == 1 ? (<>
                    {menuItemsCorporate &&
                      menuItemsCorporate.map((item, index) => {
                        return (
                          <li className={"nav-item " + (pathname == item.to ? "active" : "")} key={index}>
                            <Link to={item.to} style={{ backgroundColor: pathname == item.to ? theme.colors.primary : "" }} onClick={SidebarRemoveClass}>
                              <i className={item.icon}></i>
                              {t(item.label)}
                            </Link>
                          </li>
                        );
                      })}
                  </>) : (<>
                    {menuItems &&
                      menuItems.map((item, index) => {
                        return (
                          <li className={"nav-item " + (pathname == item.to ? "active" : "")} key={index}>
                            <Link to={item.to} style={{ backgroundColor: pathname == item.to ? theme.colors.primary : "" }} onClick={SidebarRemoveClass}>
                              <i className={item.icon}></i>
                              {t(item.label)}
                            </Link>
                          </li>
                        );
                      })}
                  </>)
                }


                <li className="nav-item">
                  <a onClick={this.onLogout}>
                    <i className="fas fa-sign-out-alt"></i>
                    {t("menu.logout")}
                  </a>
                </li>
              </ul>
            </nav>
          </>
        )}
      </Translation>
    );
  }
}
