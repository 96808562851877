import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./assets/css/Style.css";

import AuthService from "./services/auth.service";
import MainContext from "./helpers/MainContext";
import i18next from "i18next";
import Http from "./helpers/Http";
import ApiRoutes from "./helpers/ApiRoutes";

/** Layouts **/
import MainLayout from "./layouts/MainLayout";
import LoginLayout from "./layouts/LoginLayout";
import DashboardLayout from "./layouts/DashboardLayout";

/** routesItems **/
import routesItems from "./Routes";
import NotificationManager from "react-notifications/lib/NotificationManager";
import firebase from "./helpers/firebase";
import moment from "moment";

class App extends Component {
  static contextType = MainContext;
  constructor(props) {
    super(props);

    const authUser = AuthService.getAuthUserData();
    this.state = {
      authUser: authUser,
      cartItems: 0,
      settingsInfo: null,
      checkoutDetails: null,
      additional_info: null,

      globalPickUpdate: "",
      globalPickUptime: "",

      globaldeliverydate: "",
      globaldeliverytime: "",
      globalExpress: false,
      globalPickUpaddress: "",
      globaldeliveryaddress: "",
      globalDeliveryMethod: "",
      specialRequest: "",
      specialRequestText: "",

      setCheckOut: this.setCheckOut,
      setCartItem: this.setCartItem,
      setAdditionalInfo: this.setAdditionalInfo,
      setSettingsInfo: this.setSettingsInfo,
      setGlobalPickUpdate: this.setGlobalPickUpdate,
      setGlobalPickUptime: this.setGlobalPickUptime,
      setGlobaldeliverydate: this.setGlobaldeliverydate,
      setGlobaldeliverytime: this.setGlobaldeliverytime,
      setGlobalExpress: this.setGlobalExpress,
      setGlobalPickUpaddress: this.setGlobalPickUpaddress,
      setGlobaldeliveryaddress: this.setGlobaldeliveryaddress,
      setGlobalDeliveryMethod: this.setGlobalDeliveryMethod,
      setSpecialRequest: this.setSpecialRequest,
      setSpecialRequestText: this.setSpecialRequestText,

    };
  }

  componentDidMount() {
    // this.trackuservisit();
    console.log(",mbnvcbnm");
    // this.checkPasswordExpiration();
    this.getFireBaseToken();
    this.getCurrentLocation();
    this.getSettingsData();
  }

  getFireBaseToken = async () => {
    try {
      const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;

      if (messaging != null) {
        await messaging.requestPermission();
        const token = await messaging.getToken({
          vapidKey: "BJx06w-xi3AtjMG7yZuhcFOtLiQUHCCkNQHlWw_NxY-KL4cjRVphNVtAgSGYwdZkn4nxD9XTNyImq0d1aDEEBnw",
        });

        AuthService.setDeviceToken(token);

        messaging.onMessage((payload) => {
          this.notify(payload.notification);
        });
        return token;
      }
    } catch (error) {
      // console.error(error);
    }
  };

  getCurrentLocation = async () => {
    navigator.geolocation.getCurrentPosition(
      (data) => {
        AuthService.setCurrentLocation(data.coords.latitude, data.coords.longitude);
      },
      (err) => {
        AuthService.setCurrentLocation("", "");
        console.log(err);
      }
    );
  };

  //   trackuservisit = async () => {
  //     let path = ApiRoutes.TRACK_USER_VISIT;
  //     let formData = new FormData();
  //     formData.append("timestamp", moment(new Date()).format('DD-MM-YYYY HH:MM:ss'));
  //     console.log(',mnbvcxdfcgvhbjnm,.');
  //    const res = await Http("PUT", path, formData);
  //    if (res) {
  //     console.log('lkjhgfhjk');
  //      const resJson = await res.json();
  //      if (res.status == 200) {
  //        NotificationManager.success(resJson.message, "Success!", 3000);
  //      } else {
  //        NotificationManager.error(resJson.message, "Error!", 3000);
  //      }
  //    } else {
  //      NotificationManager.error(i18next.t("commons.Server_Error"), "Error!", 3000);
  //    }
  //  }

  setCartItem = (cartItems) => {
    this.setState({ cartItems: cartItems });
  };

  setCheckOut = (checkoutDetails) => {
    this.setState({ checkoutDetails: checkoutDetails });
  };

  setAdditionalInfo = (additional_info) => {
    this.setState({ additional_info: additional_info });
  };

  setSettingsInfo = (settingsInfo) => {
    this.setState({ settingsInfo: settingsInfo });
  };

  setGlobalPickUpdate = (globalPickUpdate) => {
    this.setState({ globalPickUpdate: globalPickUpdate });
  };
  setGlobalPickUptime = (globalPickUptime) => {
    this.setState({ globalPickUptime: globalPickUptime });
  };

  setGlobaldeliverydate = (globaldeliverydate) => {
    this.setState({ globaldeliverydate: globaldeliverydate });
  };

  setGlobaldeliverytime = (globaldeliverytime) => {
    this.setState({ globaldeliverytime: globaldeliverytime });
  };

  setGlobalExpress = (globalExpress) => {
    this.setState({ globalExpress: globalExpress });
  };

  setGlobalPickUpaddress = (globalPickUpaddress) => {
    this.setState({ globalPickUpaddress: globalPickUpaddress });
  };

  setGlobaldeliveryaddress = (globaldeliveryaddress) => {
    this.setState({ globaldeliveryaddress: globaldeliveryaddress });
  };
  setGlobalDeliveryMethod = (globalDeliveryMethod) => {
    this.setState({ globalDeliveryMethod: globalDeliveryMethod });
  }
  setSpecialRequest = (specialRequest) => {
    this.setState({ specialRequest: specialRequest });
  }
  setSpecialRequestText = (specialRequestText) => {
    this.setState({ specialRequestText: specialRequestText })
  }
  notify = (notification) => NotificationManager.info(notification.body, notification.title, 5000);

  getSettingsData = async () => {
    let path = ApiRoutes.GET_SETTINGS;
    const res = await Http("GET", path);
    if (res) {
      const resJson = await res.json();

      if (res.status == 200) {
        AuthService.setSettings(resJson.data.settingsData);
      } else {
        NotificationManager.error(resJson.message, "Error!", 3000);
      }
    } else {
      NotificationManager.error(i18next.t("commons.Server_Error"), "Error!", 3000);
    }
  };


  // checkPasswordExpiration = async () => {
  //   try {
  //     const path = ApiRoutes.CHECK_PASSWORD_EXPIRATION;
  //     const res = await Http("GET", path);

  //     if (res && res.status === 200) {
  //       const resJson = await res.json();
  //       if (resJson.passwordExpired) {
  //         // Show notification or popup and log out
  //         NotificationManager.info('Your password has expired. Please reset your password.');
  //         this.handleLogout(); // Log out the user
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error checking password expiration:', error);
  //   }
  // };

  render() {
    const { authUser } = this.state;

    return (
      <>
        <MainContext.Provider value={this.state}>
          <Router basename="/">
            <Switch>
              <Redirect exact from="/" to="/home" />
              <Redirect exact from="/dashboard" to="/manage-profile" />

              {(routesItems || []).map((item, idx) => {
                if (item.layout == "mainLayout") {
                  return <MainLayout key={idx} path={item.path} component={item.component} authUser={authUser} />;
                }

                if (item.layout == "unAuthLayout") {
                  return <LoginLayout key={idx} path={item.path} component={item.component} authUser={authUser} />;
                }

                if (item.layout == "authLayout") {
                  return <DashboardLayout key={idx} path={item.path} component={item.component} authUser={authUser} />;
                }
              })}

              <Redirect to="/page-not-found" />
            </Switch>
          </Router>

          <NotificationContainer />
        </MainContext.Provider>
      </>
    );
  }
}
export default App;
