import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledWithoutHoverLink = styled(Link)`
  background-color: ${({ theme }) => theme.colors.primary} !important;
  color: ${({ theme }) => theme.colors.secondary} !important;
  border: 1px solid ${({ theme }) => theme.colors.primary} !important; /* Initial border */
  cursor: pointer;
  margin-left: 8px; /* Adjust left margin */
  margin-right: 8px; /* Adjust right margin */
  transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* Smooth transitions */
`;

export default StyledWithoutHoverLink;
